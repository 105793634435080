$gray200: #e2e8f0;
$gray300: #cbd5e0;
$Blue500: #2b5dc2;
$Blue600: #224a9b;
$red500: #e53e3e;
$turquoise400: #03c7cd;

.checkbox {
  span[aria-hidden="true"] {
    border: 3px solid $gray300 !important;
  }

  span[data-checked] {
    border: none !important;
    background: $turquoise400 !important;
  }

  span[data-checked][data-disabled="false"][data-hover] {
    border: none !important;
    background: $Blue600 !important;
  }

  span[data-invalid] {
    border: 3px solid $red500 !important;
  }

  span[data-disabled] {
    border: 3px solid $gray300 !important;
    background: $gray200 !important;
  }

  span[data-checked][data-disabled] {
    border: none !important;
    background: $gray200 !important;
  }
}
